<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','grant_link']"
            ref="list"
        >
		<template slot="grant_link" slot-scope="data">
				<a @click="grant_link(data.record)">{{data.text}}</a>
			</template>
            <template slot="right_btn">
                <export-table
                    v-if="this.$store.state.page_auth.export"
                    url="/manage/crm.settlement/exportUnsettlement"
                ></export-table>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a @click="details(data.record)">查看</a>
                </span>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { unsettlementList } from "@/api/cost";
import { getType } from '@/api/approval'
let columns = [
    {
        title: "结算单位ID",
        dataIndex: "dealer_id",
    },
    {
        title: "结算单位名称",
        dataIndex: "customer_name",
    },
    {
        title: "等级",
        dataIndex: "level_name",
    },
    {
        title: "联系人",
        dataIndex: "contact_name",
    },
    {
        title: "联系电话",
        dataIndex: "contact_phone",
    },
    {
        title: "地址",
        dataIndex: "address",
    },
    {
        title: "关联发放记录",
        dataIndex: "grant_link",
		scopedSlots: {
            customRender: "grant_link"
        }
    },
    {
        title: "结算月份",
        dataIndex: "month",
    },
    {
        title: "操作",
        width: 100,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: unsettlementList,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
            form_data_seo: {
                list: [
                    // {
                    //     type: "tree-select",
                    //     name: "department_id",
                    //     title: "部门",
                    //     options: {},
                    //     treeData: [],
                    //     multiple: true
                    // },
                    {
                        type: "text",
                        name: "keyword",
                        title: "结算单位",
                        placeholder: '输入ID、名称',
                        options: {

                        }
                    },
                    {
                        type: "select",
                        name: "level",
                        title: "客户等级",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "text",
                        name: "contact",
                        title: "结算单位联系人",
                        placeholder: '输入姓名、手机号',
                        options: {

                        }
                    },
                    {
                        type: "month",
                        name: "month",
                        title: "结算月份",
                        mode: 'month',
                        options: {

                        }
                    },
                    // {
                    //     type: "range_date",
                    //     name: "range_date",
                    //     title: "创建时间",
                    //     options: {},
                    //     start: {
                    //         name: 'start_time'
                    //     },
                    //     end: {
                    //         name: 'end_time'
                    //     },
                    // },
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
        // this.$method.get_department().then(res => {
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        // });

        Promise.all([this.$method.get_level()]).then(res => {
            this.form_data_seo.list.forEach(item => {
                if (item.name == "level") {
                    item.list = res[0];
                }
            });
        });
    },
    methods: {
        details(data) {
            this.$router.push('settlement_edit?id=' + data.id)
        },
		grant_link(data){
			this.$router.push('grant?grant_id='+data.grant_id)
		},
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>